import { BookingI, BookingTypeE, CurrencyE } from '@/types/cyclone/models';
// import * as amplitude from '@amplitude/analytics-browser';
import { MixpanelPage } from './events';

// const AMPLITUDE_API_KEY = '9b9866917c3165f29b0383a54679ca7';
// For
declare global {
  interface Window {
    analytics: any;
    dataLayer: any;
    fbq: any;
  }
}

export interface CustomProps {
  screen: Screen;
  [propName: string]: unknown;
}

/* amplitude.init(AMPLITUDE_API_KEY, {
  trackingOptions: {
    ipAddress: true,
    language: true,
    platform: true
  },
  logLevel: amplitude.Types.LogLevel.None
}); */

const getUTMParameters = () => {
  if (typeof window === 'undefined') return {}; // Ensure this runs in the browser

  const searchParams = new URLSearchParams(window.location.search);
  const utmParams = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content'];
  const params: any = {};

  utmParams.forEach((param) => {
    const value = searchParams.get(param);
    if (value) params[param] = value;
  });

  return params;
};

/**
 * A generic wrapper for the Mixpanel track method that can be used to track any arbitrary event.
 * The properties passed as arguments are sent to Mixpanel as is.
 * @param  name - Name of the event
 * @param  customProps - (Optional) Object of arbitrary properties that will be sent to Mixpanel
 */
export const trackGenericEvent = (name: any, customProps?: Partial<CustomProps>): void => {
  if (process.env.NODE_ENV === 'production') {
    const utmParams = getUTMParameters();

    /* amplitude.track(name, {
      ...customProps,
      ...utmParams
    }); */
  } else {
    console.log(`Tracking event ${name} with properties:`, customProps);
  }
};

export const trackGenericPage = (name: MixpanelPage, customProps?: Partial<CustomProps>): void => {
  const utmParams = getUTMParameters();
  /* amplitude.track(`Viewed ${name} Page`, {
    ...customProps,
    ...utmParams
  }); */
};

export const initFacebookPixel = (pixelId: string) => {
  if (!window.fbq) {
    window.fbq = function () {
      window.fbq.callMethod
        ? // eslint-disable-next-line prefer-spread
          window.fbq.callMethod.apply(window.fbq, arguments)
        : window.fbq.queue.push(arguments);
    };
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);
  }

  window.fbq('init', pixelId);
  window.fbq('track', 'PageView');
};

export const trackAddToCart = (contentName: string, contentId: string) => {
  console.log(`Sending Facebook AddToCart event for content ${contentName}`);
  window.fbq('track', 'AddToCart', {
    content_name: contentName,
    content_ids: [contentId],
    content_type: 'product'
  });
};

export const trackInitiateCheckout = () => {
  window.fbq('track', 'InitiateCheckout');
};

export const trackLandingPageView = () => {
  if (!window.fbq) {
    window.fbq = function () {
      window.fbq.callMethod
        ? // eslint-disable-next-line prefer-spread
          window.fbq.callMethod.apply(window.fbq, arguments)
        : window.fbq.queue.push(arguments);
    };
    window.fbq.push = window.fbq;
    window.fbq.loaded = true;
    window.fbq.version = '2.0';
    window.fbq.queue = [];

    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://connect.facebook.net/en_US/fbevents.js';
    document.head.appendChild(script);
  }

  window.fbq('init', '750381646381609');
  window.fbq('track', 'PageView Paid');
};

export const trackPurchase = (booking: BookingI) => {
  console.log(`Sending Facebook Purchase event for booking ${booking.id}`);
  try {
    const payment = booking.payments![0];

    const contentName =
      booking.type === BookingTypeE.EVENT_INSTANCE
        ? booking.booking_event_instance?.event_instance?.event!.service.name
        : booking.type === BookingTypeE.DIGITAL_CONTENT
          ? booking.booking_digital_content?.digital_content?.name
          : BookingTypeE.UNIQUE_EVENT
            ? booking.booking_unique_event?.unique_event?.name
            : 'No content name';

    const currency = payment.currency === CurrencyE.ARS ? 'ARS' : 'USD';
    const value = payment.amount;

    // Sending the Purchase event to Facebook Pixel
    window.fbq('track', 'Purchase', {
      content_name: contentName,
      currency: currency,
      value: value
    });
  } catch (error) {
    console.error('Error triggering Facebook conversion:', error);
  }
};
