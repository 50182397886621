import { useLocalStorage } from './useLocalStorage';
import { QueryCache } from '@tanstack/react-query';
import useLog from './useLog';

const queryCache = new QueryCache();
const NIGHTWING_URL = process.env.NEXT_PUBLIC_NIGHTWING_URL;

interface useClientI {
  client: ClientI;
}

type EndpointRequest = 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';

interface ClientI {
  <T>(
    endpoint: string,
    type?: EndpointRequest,
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    options?: { isAuthRequired?: boolean; data?: any }
  ): Promise<T>;
}

export const useClient = (): useClientI => {
  const { deleteValue } = useLocalStorage('session', {});
  const API_URL = process.env.NEXT_PUBLIC_CYCLONE_API_URL || 'INSERT_CYCLONE_API_URL';

  const logOutUser = async () => {
    deleteValue('session');
    const { Auth0Client } = await import('@auth0/auth0-spa-js');
    const auth0 = new Auth0Client({
      domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN!,
      clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!
    });
    await auth0.logout({
      logoutParams: { returnTo: `${NIGHTWING_URL}/logout` }
    });
  };

  const client: ClientI = async (endpoint, type = 'GET', { data, isAuthRequired } = {}) => {
    let authHeader = {};

    if (isAuthRequired) {
      try {
        const { Auth0Client } = await import('@auth0/auth0-spa-js');
        const auth0 = new Auth0Client({
          domain: process.env.NEXT_PUBLIC_AUTH0_DOMAIN!,
          clientId: process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID!
        });
        const token = await auth0.getTokenSilently({
          authorizationParams: {
            audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
            scope: 'openid profile email'
          }
        });
        authHeader = { Authorization: `Bearer ${token}` };
      } catch (error) {
        // We're not going to pass the auth header
      }
    }

    const config = {
      method: type,
      body: data ? JSON.stringify(data) : undefined,
      headers: {
        'Content-Type': 'application/json',
        ...authHeader
      }
    };

    return fetch(`${API_URL}/${endpoint}`, config).then(async (response) => {
      if (response.status === 401) {
        queryCache.clear();
        await logOutUser();
        // refresh the page for them
        window.location.assign('/status/401');
        return Promise.reject({ message: 'Please re-authenticate.' });
      }

      const data = await response.json();
      if (response.ok) {
        return data;
      } else {
        return Promise.reject(data);
      }
    });
  };

  return { client };
};
