import { BookingWithRefundAvailability } from './requests';

export interface UserI {
  id: number;
  auth_id: string;
  first_name: string;
  last_name: string;
  email: string;
  connection_type: string;
  phone?: string;
  dob?: string | null;
  document_number?: string | null;
  avatar_url?: string;
  updated_at?: string;
  is_admin?: boolean;
  is_email_verified: boolean;
  vendor: VendorI;
  created_at: string;
}

export interface VendorI {
  longitude: string | undefined;
  latitude: string | undefined;
  id: number;
  username: string;
  plan_name?: string;
  address?: string;
  country: SupportedCountriesE;
  timezone: string;
  state: string;
  bio: string;
  professional_bio: string;
  professional_title: string;
  rating: number;
  cover_url: string;
  agora_fee: string;
  status: VendorStatusE;
  is_discount_affect_down_payment: boolean;
  facebook_pixel_id?: string;
  default_gateway: string;
  payment_gateways?: WalletTypeE[];
  is_published: boolean;
  is_payment_cash_enabled: boolean;
  is_payment_bank_transfer_enabled: boolean;
  is_working_hours_global: boolean;
  max_booking_period: number;
  venue_id?: number;
  created_at: string;
  updated_at?: string;
  user_id?: number;
  user: UserI;
  services?: ServiceI[];
  subscriptions?: SubscriptionI[];
  certifications?: CertificationI[];
  experiences?: ExperienceI[];
  unique_events?: UniqueEventI[];
  digital_contents?: DigitalContentI[];
  vendor_working_hours?: VendorWorkingHoursI[];
  vendor_working_hours_start_frequency?: number;
  vendor_on_demand_hours?: VendorOnDemandHoursI[];
  offline_payments?: OfflinePaymentI[];
  vendor_links?: VendorLinkI[];
  vendor_social_apps?: VendorSocialAppI[];
  vendor_storefront: VendorStorefrontI;
  vendor_blocked_hours?: VendorBlockedHoursI[];
  vendor_agora_plans: VendorPlanI[];
  venue?: VenueI;
  featured_videos: FeaturedI[];
}

export interface VendorOnDemandHoursI {
  id: number;
  vendor_id: number;
  service_id?: number;
  start_at: Date;
  working_hours: WorkingHours[];
  service?: ServiceI;
  vendor: VendorI;
}

export enum VendorStatusE {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  DELETED = 'deleted'
}

//
export interface VenueI {
  id: number;
  vendor_owner_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor_owner: VendorI;
  vendors?: VendorI[];
}

export interface FeaturedI {
  type: AgoraLinkTypeE;
  title: string | null;
  url?: string;
  address?: string;
  latitude?: string;
  longitude?: string;
}
export interface NotificationI {
  id: number;
  email_messages: boolean;
  sms_messages: boolean;
  whatsapp_messages: boolean;
  email_reminders: boolean;
  sms_reminders: boolean;
  whatsapp_reminders: boolean;
}

export interface SocialMediaI {
  id: number;
  instagram_link?: string;
  linked_in_link?: string;
  youtube_link?: string;
  facebook_link?: string;
  web_link?: string;
}

export interface CertificationI {
  id: number;
  title: string;
  description: string;
  order: number;
}

export interface ExperienceI {
  id: number;
  title: string;
  description: string;
  order: number;
}

export interface EventI {
  id: number;
  service_id: number;
  price: number;
  days: number[];
  local_slots: number;
  virtual_slots: number;
  modality: ServiceModalityE;
  duration: number;
  is_recurrent: boolean;
  recurrence_pattern?: string;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  service: ServiceI;
  event_instances?: EventInstanceI[];
}

export interface EventInstanceI {
  id: number;
  address?: string;
  address_details?: string;
  latitude?: string;
  longitude?: string;
  neighborhood?: string;
  country?: SupportedCountriesE;
  state?: string;
  local_slots: number;
  virtual_slots: number;
  price: number;
  participants: number;
  modality: ServiceModalityE;
  status: ScheduleStatusT;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  event_id: number;
  event?: EventI;
}

export interface VirtualMeetI {
  id: number;
  meet_type: MeetTypeE;
  meet_link?: string;
  room_name?: string;
  has_started: boolean;
  started_at: Date;
  ended_at: Date;
  event_instance: EventInstanceI;
}

export interface ServiceI {
  id: number;
  vendor_id: number;
  name: string;
  description: string;
  slug: string;
  address: string;
  address_details: string;
  latitude: string;
  longitude: string;
  neighborhood: string;
  country?: SupportedCountriesE;
  state?: string;
  local_slots: number;
  virtual_slots: number;
  duration: number;
  modality: ServiceModalityE;
  price: number;
  price_usd: number;
  rating: number;
  images_gallery?: string[];
  cover_url: string;
  status: ServiceStatusE;
  days: number[];
  order: number;
  booking_until_at_minutes: number;
  policy_refund_percentage: number;
  policy_refund_mins: number;
  is_user_phone_required: boolean;
  is_price_hidden: boolean;
  meet_type: MeetTypeE;
  meet_link?: string;
  created_at: string;
  updated_at?: string;
  category_sub_category?: CategorySubCategoryI;
  service_subscriptions: ServiceSubscriptionI[];
  vendor?: VendorI;
  multi_vendors?: ServiceVendorI[];
  events?: EventI[];
  packs?: PackI[];
  is_free_trial_available: boolean;
  is_for_subscription: boolean;
  is_on_demand: boolean;
  is_refund_policies_enabled: boolean;
  user_has_access_to_service_subscription: boolean;
  coverage_areas?: CoverageAreaI[];
  faqs?: FAQI[];
  assets_labels?: AssetLabelI[];
  down_payment?: DownPaymentI;
  custom_questions?: CustomQuestionI[];
}

export interface ServiceVendorI {
  service_id: number;
  vendor_id: number;
  service: ServiceI;
  vendor: VendorI;
}

export interface SubscriptionI {
  id: number;
  payment_link: string;
  vendor_id: number;
  participants: number;
  name: string;
  description: string;
  slug: string;
  cover_url: string;
  payment_recurrence: SubscriptionRecurrenceE;
  status: SubscriptionStatusE;
  price: number;
  price_usd?: number;
  slots: number;
  order: number;
  created_at?: string;
  updated_at?: string;
  vendor?: VendorI;
  service_subscriptions: ServiceSubscriptionI[];
  service_exception_subscriptions?: ServiceExceptionSubscriptionI[];
  faqs?: FAQI[];
}

export interface ServiceSubscriptionI {
  limit: number | null;
  is_limited: boolean;
  service_id: number;
  subscription_id: number;
  service: ServiceI;
  subscription?: SubscriptionI;
}

export interface ServiceExceptionSubscriptionI {
  service_exception_id: number;
  subscription_id: number;
  service_exception: ServiceExceptionI;
  subscription?: SubscriptionI;
}

export interface ServiceExceptionI {
  id: number;
  name: string;
}

export interface SubCategoryI {
  id: number;
  name: string;
}

export interface CategoryI {
  id: number;
  name: string;
}

export interface CategorySubCategoryI {
  id: number;
  category: CategoryI;
  sub_category: SubCategoryI;
}

export interface PackI {
  id: number;
  service_id: number;
  limit: number;
  price: number;
  discount: number;
  created_at: string;
  updated_at?: string;
  service: ServiceI;
}

// Virtual Schemas

export interface VirtualSchedulesI {
  start_at: string;
  end_at: string;
  modality: ServiceModalityE;
}

export interface VirtualSchedulesAvailabilityI {
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  duration: number;
  vendor_id: number;
  service_id: number;
}

export interface VirtualEventInstancesI {
  id: number;
  local_slots: number;
  virtual_slots: number;
  price: number;
  participants: number;
  status: ScheduleStatusT;
  start_at: string;
  end_at: string;
  start_time: string;
  end_time: string;
  created_at: string;
  updated_at?: string;
  event_id: number;
  event: Omit<EventI, 'event_instances'>;
  modality: ServiceModalityE;
}

export interface BookingPackI {
  id: number;
  limit: number;
  price: number;
  price_usd?: number;
  discount: number;
  service_id: number;
  booking_id: number;
  created_at: string;
  updated_at?: string;
  service: ServiceI;
  booking?: BookingI;
  is_refund_available: boolean;
}

export interface BookingSubscriptionI {
  id: number;
  is_auto_renewal_enabled: boolean;
  status: BookingSubscriptionStatusE;
  start_at: string;
  subscription_id: number;
  booking_id: number;
  created_at: string;
  updated_at?: string;
  subscription: SubscriptionI;
  booking?: BookingI;
  bookings_associated?: BookingWithRefundAvailability[];
  tickets?: TicketI[];
  end_at: string;
}

export interface BookingUniqueEventI {
  id: number;
  quantity: number;
  booking_id: number;
  unique_event_id: number;
  unique_event_ticket_id: number;
  unique_event_ticket_special_id: number;
  created_at: Date;
  updated_at?: Date;
  booking: BookingI;
  unique_event: UniqueEventI;
  unique_event_ticket: UniqueEventTicketI;
  unique_event_ticket_special?: UniqueEventTicketI;
  unique_code: string;
}

export interface BookingDigitalContentI {
  id: number;
  booking_id: number;
  digital_content_id: number;
  created_at: Date;
  updated_at?: Date;
  booking: BookingI;
  digital_content: DigitalContentI;
}

export interface BookingEventInstanceI {
  id: number;
  modality: ServiceModalityE;
  price: number;
  event_instance_id: number;
  booking_id: number;
  created_at: string;
  updated_at?: string;
  event_instance: EventInstanceI;
  booking?: BookingI;
}

export interface BookingDonationI {
  id: number;
  comment: string;
  booking_id: number;
  crowdfunding_plan_id: number;
  created_at: Date;
  updated_at?: Date;
  crowdfunding_plan?: CrowdfundingPlanI;
  booking: BookingI;
}

export interface BookingI {
  id: number;
  type: BookingTypeE;
  status: BookingStatusE;
  rescheduled_by_user_id: number;
  rescheduled_at: Date;
  cancelled_by_user_id: number;
  cancelled_at: Date;
  user_id: number;
  vendor_id: number;
  parent_booking_id: number | null;
  created_at: string;
  updated_at?: string;
  payments?: PaymentI[];
  parent_booking?: BookingI;
  user?: UserI;
  vendor: VendorI;
  booking_event_instance?: BookingEventInstanceI;
  booking_pack?: BookingPackI;
  booking_subscription?: BookingSubscriptionI;
  booking_donation?: BookingDonationI;
  booking_unique_event?: BookingUniqueEventI;
  booking_digital_content?: BookingDigitalContentI;
  cancelled_by_user?: UserI;
  rescheduled_by_user?: UserI;
  refund?: RefundI;
}

export interface RefundI {
  id: number;
  type: RefundTypeE;
  status: PaymentStatusE;
  amount: number;
  service_policy_refund_percentage: number;
  created_at: string;
  updated_at?: string;
  booking_id: number;
}

export interface AssetI {
  id: number;
  pack_id: number;
  subscription_id: number;
  user_id: number;
  status: AssetStatusE;
  start_at: string;
  end_at: string;
  user: UserI;
  pack?: PackI;
  subscription?: SubscriptionI;
  created_at: string;
  updated_at?: string;
  tickets?: TicketI[];
}

export interface PaymentI {
  id: number;
  booking_id: number;
  down_payment_id: number;
  vendor_agora_plans_invoice_id: number;
  coupon_id: number;
  payment_link: string;
  payment_link_start_at: Date;
  payment_link_end_at: Date;
  type?: PaymentTypeE;
  status: PaymentStatusE;
  currency?: CurrencyE;
  amount: number;
  down_payment_amount: number;
  down_payment_type?: PaymentTypeE;
  down_payment_at?: Date;
  confirmed_at?: Date;
  discount: number;
  discount_by_payment_method: number;
  discount_by_coupon: number;
  agora_comission: number;
  is_offline_payment_confirmed?: boolean;
  total_amount: number;
  gateway: WalletTypeE;
  created_at: Date;
  updated_at?: Date;
  booking?: BookingI;
  vendor_agora_plans_invoice?: VendorAgoraPlanInvoiceI;
  down_payment?: DownPaymentI;
  coupon?: CouponI;
}

export interface DownPaymentI {
  id: number;
  amount: number;
  amount_discount: number;
  is_mercado_pago_available: boolean;
  is_bank_transfer_available: boolean;
  is_cash_available: boolean;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  service_id: number;
  service: ServiceI;
  vendor: VendorI;
}

export interface TicketI {
  id: number;
  booking_pack_id: number;
  is_limited: boolean;
  limit: number;
  service_id: number;
  status: TicketStatusE;
  service?: ServiceI;
  booking_pack?: BookingPackI;
  booking_subscription?: BookingSubscriptionI;
}

export interface InvitationI {
  id: number;
  status: InvitationStatus;
  user_invitee_email: string;
  user_invitor_id: number;
  user_invitee_id: number;
  created_at: Date;
  updated_at?: Date;
  user_invitor: UserI;
  user_invitee: UserI;
}

export interface CrowdfundingPlanI {
  id: number;
  title: string;
  description: string;
  status: CrowdfundingPlanStatusE;
  participants: number;
  amount_to_collect: number;
  show_amount_to_collect: boolean;
  amount_recollected: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
}

export interface CouponI {
  id: number;
  status: CouponStatusE;
  user_email: string;
  apply_all_assets: boolean;
  amount_discount?: number;
  amount_usd_discount?: number;
  discount?: number;
  end_at: Date;
  user_id: number;
  vendor_id: number;
  service_id?: number;
  subscription_id?: number;
  unique_event_id?: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  subscription?: SubscriptionI;
  unique_event_ticket?: UniqueEventTicketI;
  service?: ServiceI;
  user?: UserI;
}

interface WorkingHours {
  start_time: string;
  end_time: string;
}

export interface VendorWorkingHoursI {
  id: number;
  day: number;
  recurrence_pattern: string;
  working_hours: WorkingHours[];
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  service_id: number;
  vendor: VendorI;
}

export interface PolygonArea {
  lat: number;
  lng: number;
}
export interface CircleArea {
  center: {
    lat: number;
    lng: number;
  };
  radius: number;
}
export interface CoverageAreaI {
  id: number;
  shape: CoverageAreaShapeE;
  area: PolygonArea[] | CircleArea;
  color: string;
  name: string;
  created_at: Date;
  updated_at?: Date;
  service_id: number;
  service: ServiceI;
}

export interface VendorAgoraPlanI {
  id: number;
  vendor_id: number;
  agora_plan_id: number;
  is_free_trial: boolean;
  has_reached_total_monthly_txs: boolean;
  is_free_trial_exceeded: boolean;
  payment_recurrence?: SubscriptionRecurrenceE;
  status: VendorAgoraPlanSubscriptionStatusE;
  start_at: Date;
  payment_date?: Date;
  limit_payment_date?: Date;
  expired_reason?: string;
  total_txs: number;
  total_monthly_txs: number;
  free_trial_txs_allowed?: number;
  mp_subscription_id?: string;
  incoming_plan_renewal_id?: number;
  incoming_plan_renewal_date?: Date;
  reached_total_monthly_txs_date?: Date;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
  agora_plan?: AgoraPlanI;
  incoming_plan_renewal?: VendorAgoraPlanI;
  vendor_agora_plan_invoices?: VendorAgoraPlanInvoiceI[];
}

export interface AgoraPlanI {
  id: number;
  name: AgoraPlanNameE;
  price_monthly?: number;
  price_yearly?: number;
  txs_allowed?: number;
  free_trial_txs_allowed?: number;
  is_reports_available: boolean;
  is_coupons_available: boolean;
  is_vendor_wa_profile_link_available: boolean;
  created_at: Date;
  updated_at?: Date;
}

export interface VendorAgoraPlanInvoiceI {
  id: number;
  vendor_agora_plan_id: number;
  status?: VendorAgoraPlanInvoiceStatusE;
  plan_subscription_start_at: Date;
  plan_subscription_end_at: Date;
  expiration_date: Date;
  created_at: Date;
  updated_at?: Date;
  vendor_agora_plan?: VendorAgoraPlanI;
  next_retry_date?: Date;
  debit_date?: Date;
  retry_attempt: number;
  mp_authorized_payment_id?: string;
  payment?: PaymentI;
}

export interface FAQI {
  id: number;
  name: string;
  description: string;
  order: number;
  service_id?: number;
  subscription_id?: number;
  created_at: Date;
  updated_at?: Date;
  service?: ServiceI;
  subscription?: SubscriptionI;
  uniqueEvent?: UniqueEventI;
}

export interface UniqueEventI {
  id: number;
  name: string;
  description: string;
  slug: string;
  address?: string;
  address_details?: string;
  latitude?: string;
  longitude?: string;
  neighborhood?: string;
  country?: SupportedCountriesE;
  state?: string;
  modality: ServiceModalityE;
  duration: number;
  cover_url: string;
  status: UniqueEventStatusE;
  policy_refund_percentage: number;
  policy_refund_mins: number;
  booking_until_at_minutes: number;
  meet_type?: MeetTypeE;
  meet_link?: string;
  images_gallery: string[];
  password?: string;
  order: number;
  is_policy_available: boolean;
  is_transfer_fee_available: boolean;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  category_sub_category_id: number;
  vendor?: VendorI;
  category_sub_category?: CategorySubCategoryI;
  unique_events_tickets: UniqueEventTicketI[];
  unique_events_schedules: UniqueEventScheduleI[];
  sold_tickets: number;
  is_booking_until_at_after: boolean;
  faqs: FAQI[];
  is_limited_tickets: boolean;
  is_secret_location: boolean;
  is_user_phone_required: boolean;
  is_hidden: boolean;
  max_tickets_quantity: number;
  custom_questions?: CustomQuestionI[];
}

interface UniqueEventScheduleI {
  id: number;
  unique_event_id: number;
  start_at: Date;
  end_at: Date;
  created_at: Date;
  updated_at?: Date;
}

interface CustomQuestionI {
  id: number;
  question: string;
  is_required: boolean;
  order: number;
  unique_event_id: number;
  service_id: number;
  unique_event: UniqueEventI;
  service: ServiceI;
}

export interface UniqueEventTicketI {
  id: number;
  name: string;
  price: number;
  price_usd?: number;
  slots: number;
  is_hidden: boolean;
  modality: ServiceModalityE;
  total_slots: number;
  redeemed_slots: number;
  quantity: number;
  unique_event_id: number;
  parent_ticket_id?: number;
  unique_event: UniqueEventI;
  parent_ticket?: UniqueEventTicketI;
}

export interface OfflinePaymentI {
  id: number;
  type: OfflinePaymentTypeE;
  description: string;
  discount: number;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
}

export interface VendorLinkI {
  id: number;
  name: string;
  is_agora_link: boolean;
  agora_link_type?: AgoraLinkTypeE;
  url?: string;
  is_hidden: boolean;
  order: number;
  vendor_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
}

export interface DigitalContentI {
  id: number;
  name: string;
  description: string;
  slug: string;
  cover_url: string;
  content_url: string;
  price: number;
  price_usd?: number;
  status: DigitalContentStatusE;
  is_user_phone_required: boolean;
  is_transfer_fee_available: boolean;
  images_gallery: string[];
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
  faqs?: FAQI[];
  assets_labels?: AssetLabelI[];
}

export interface VendorStorefrontI {
  id: number;
  vendor_id: number;
  template_name: AgoraThemeE;
  cover_url?: string;
  cover_desktop_url?: string;
  name: string;
  background_color: string;
  title_color: string;
  button_color: string;
  text_color: string;
  created_at: Date;
  updated_at?: Date;
  button_type: TypeButtonsE;
  border_radius_px: RoundedE;
  title_font_type: TitleTypeFontE;
  font_type: SubtitleTypeFontE;
  vendor?: VendorI;
  featured_video_title: string | null;
  featured_video_url: string | null;
  shadow_color: string;
  pattern_type?: PatternE | null;
  is_banner_displayed: boolean;
  is_avatar_displayed: boolean;
  size_banner: SizeImageE;
  size_avatar: SizeImageE;
}

export interface SocialAppI {
  id: number;
  name: string;
}

export interface VendorSocialAppI {
  id: number;
  vendor_id: number;
  social_app_id: number;
  link: string;
  order: number;
  created_at: Date;
  updated_at?: Date;
  social_app?: SocialAppI;
  vendor?: VendorI;
}

export interface VendorBlockedHoursI {
  id: number;
  start_at: string;
  start_time: string;
  end_at: string;
  end_time: string;
  created_at: Date;
  updated_at?: Date;
  vendor_id: number;
  vendor: VendorI;
}

export interface VendorPlanI {
  agora_plan_id: number;
  created_at: Date;
  event_fee_percentage: number;
  expired_reason: string | null;
  free_trial_txs_allowed: number | null;
  id: number;
  incoming_plan_renewal_date: Date | null;
  incoming_plan_renewal_id: number;
  is_free_trial: boolean;
  is_free_trial_exceeded: boolean;
  limit_payment_date: Date | null;
  mp_subscription_id: string | null;
  payment_date: Date | null;
  payment_recurrence: SubscriptionRecurrenceE;
  start_at: Date;
  status: VendorAgoraPlanSubscriptionStatusE;
  total_txs: number;
  updated_at: Date;
  vendor_id: number;
}

export interface AgoraThemeI {
  name: AgoraThemeE;
  colors: AgoraThemeColorI;
  pattern_type?: PatternE | null;
}

export interface AgoraThemeColorI {
  title: string;
  background: string;
  text: string;
  button: string;
  shadow: string;
}

export interface LabelI {
  id: number;
  title: string;
  order: number;
  vendor_id: number;
  created_at: Date;
  updated_at?: Date;
  vendor: VendorI;
}

export interface AssetLabelI {
  id: number;
  service_id: number;
  label_id: number;
  created_at: Date;
  updated_at?: Date;
  service: ServiceI;
  label: LabelI;
}

export enum WalletTypeE {
  MERCADO_PAGO = 'mercado_pago',
  STRIPE = 'stripe'
}

export enum AgoraThemeE {
  SOHO = 'soho',
  MIXTAPE = 'mixtape',
  CLEAN = 'clean'
}

export enum OfflinePaymentTypeE {
  CASH = 'cash',
  BANK_TRANSFER = 'bank_transfer'
}

export enum UniqueEventStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  FINISHED = 'finished',
  DELETED = 'deleted'
}

export enum VendorAgoraPlanSubscriptionStatusE {
  PENDING = 'pending',
  WAITING_FOR_GATEWAY = 'waiting_for_gateway',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired'
}

export enum DigitalContentStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum AgoraPlanNameE {
  EVENT = 'events',
  PROFESSIONAL = 'professional'
}

export enum VendorAgoraPlanInvoiceStatusE {
  PENDING = 'pending',
  PAID_OUT = 'paid_out',
  SCHEDULED = 'scheduled',
  RECYCLING = 'recycling',
  CANCELED = 'canceled'
}

export enum CoverageAreaShapeE {
  CIRCLE = 'circle',
  POLYGON = 'polygon'
}

export type ScheduleStatusT = 'bookable' | 'paused' | 'canceled' | 'finished' | 'full';
export enum ScheduleStatusE {
  BOOKABLE = 'bookable',
  PAUSED = 'paused',
  CANCELED = 'canceled',
  FINISHED = 'finished',
  FULL = 'full'
}

export enum ServiceModalityE {
  LOCAL = 'local',
  VIRTUAL = 'virtual',
  OPEN = 'open'
}

export type BookingStatusT = 'confirmed' | 'pending' | 'canceled' | 'unpaid' | 'refunded';
export enum BookingStatusE {
  PENDING = 'pending',
  CONFIRMED = 'confirmed',
  CANCELED = 'canceled',
  UNPAID = 'unpaid',
  REFUNDED = 'refunded',
  COMPLETED = 'completed'
}

export type PaymentStatusT = 'confirmed' | 'pending';
export enum PaymentStatusE {
  PENDING = 'pending',
  DOWN_PAYMENT_PENDING = 'down_payment_pending',
  DOWN_PAYMENT_CONFIRMED = 'down_payment_confirmed',
  CONFIRMED = 'confirmed'
}

export type AssetStatusT = 'confirmed' | 'pending';
export enum AssetStatusE {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export type SubscriptionRecurrenceT = 'monthly';
export enum SubscriptionRecurrenceE {
  MONTHLY = 'monthly'
}

export type PaymentTypeT = 'wallet' | 'pack' | 'subscription';
export enum PaymentTypeE {
  WALLET = 'wallet',
  PACK = 'pack',
  SUBSCRIPTION = 'subscription',
  FREE_TRIAL = 'free_trial',
  FREE = 'free',
  CASH = 'cash',
  BANK_TRANSFER = 'bank_transfer',
  VENDOR_INVITE = 'vendor_invite'
}

export enum SupportedCountriesE {
  ARGENTINA = 'Argentina',
  URUGUAY = 'Uruguay',
  CHILE = 'Chile'
}

export enum RefundTypeE {
  PARTIAL = 'partial',
  COMPLETE = 'complete',
  TICKET = 'ticket'
}

export enum BookingSubscriptionStatusE {
  PENDING = 'pending',
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  EXPIRED = 'expired'
}

export enum BookingTypeE {
  EVENT_INSTANCE = 'event_instance',
  PACK = 'pack',
  SUBSCRIPTION = 'subscription',
  UNIQUE_EVENT = 'unique_event',
  DIGITAL_CONTENT = 'digital_content'
}

export enum TicketStatusE {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  EMPTY = 'empty'
}

export enum ServiceStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  DELETED = 'deleted'
}
export enum SubscriptionStatusE {
  PAUSED = 'paused',
  ACTIVE = 'active',
  DELETED = 'deleted'
}

export enum Auth0RolesE {
  VENDOR = 'Vendor',
  CUSTOMER = 'Customer',
  ADMIN = 'Admin'
}

export enum Auth0ConnectionTypes {
  AUTH0 = 'auth0',
  GOOGLE = 'google-oauth2',
  FACEBOOK = 'facebook'
}

export enum MeetTypeE {
  ZOOM = 'zoom',
  GOOGLE_MEET = 'google_meet',
  CUSTOM = 'custom'
}

export enum InvitationStatus {
  SENT = 'sent',
  ACCEPTED = 'accepted',
  EXPIRED = 'expired'
}

export enum CrowdfundingPlanStatusE {
  ACTIVE = 'active',
  FINISHED = 'finished',
  EXPIRED = 'expired',
  COMPLETED = 'completed'
}

export enum CouponStatusE {
  REDEEMED = 'redeemed',
  ACTIVE = 'active',
  EXPIRED = 'expired'
}

export enum AgoraLinkTypeE {
  SERVICE = 'servicios',
  SUBSCRIPTION = 'suscripciones',
  DOWNLOADABLE = 'descargables',
  DONATION = 'bancar',
  EVENT = 'eventos',
  AGENDA = 'agenda',
  ABOUT = 'bio',
  YOUTUBE = 'youtube',
  MAPS = 'maps'
}

export enum CurrencyE {
  ARS = 'ars',
  USD = 'usd',
  UYU = 'uyu',
  CLP = 'clp'
}

export enum TypeButtonsE {
  SOLID = 'solid',
  OUTLINE = 'outline',
  DARK_SHADOW = 'dark_shadow',
  SOFT_SHADOW = 'soft_shadow'
}

export enum TitleTypeFontE {
  BANGERS = 'font-bangers',
  ZEN = 'font-zen',
  PLAYFAIR = 'font-playfair',
  PLAYFAIR_SC = 'font-playfair-sc',
  ABHAYA_EXTRABOLD = 'font-abhaya-extrabold',
  CINZEL_DECORATIVE = 'font-cinzel-decorative',
  KAPAKANA = 'font-kapakana',
  MERIENDA = 'font-merienda',
  POPPINS = 'font-poppins',
  RALEWAY = 'font-raleway',
  MONTSERRAT = 'font-montserrat',
  QUATTROCENTO = 'font-quattrocento',
  MERRIWEATHER = 'font-merriweather'
}
export enum SubtitleTypeFontE {
  BANGERS = 'font-bangers',
  ZEN = 'font-zen',
  PLAYFAIR = 'font-playfair',
  POPPINS = 'font-poppins',
  RALEWAY = 'font-raleway',
  MONTSERRAT = 'font-montserrat',
  QUATTROCENTO = 'font-quattrocento',
  MERRIWEATHER = 'font-merriweather'
}

export enum RoundedE {
  ROUNDED_0 = 0,
  ROUNDED_10 = 10,
  ROUNDED_20 = 20,
  ROUNDED_30 = 30
}

export enum PatternE {
  PATTERN_1 = 'pattern_1',
  PATTERN_2 = 'pattern_2',
  PATTERN_3 = 'pattern_3',
  PATTERN_4 = 'pattern_4',
  PATTERN_5 = 'pattern_5',
  PATTERN_6 = 'pattern_6',
  PATTERN_7 = 'pattern_7',
  PATTERN_8 = 'pattern_8',
  PATTERN_9 = 'pattern_9',
  PATTERN_10 = 'pattern_10',
  PATTERN_11 = 'pattern_11',
  PATTERN_12 = 'pattern_12',
  PATTERN_13 = 'pattern_13'
}

export enum SizeImageE {
  SMALL = 'small',
  BIG = 'big'
}
